import Modal from "./Modal";

export default function AlertModal({ alertModalOpen, setAlertModalOpen }) {
  return (
    <Modal hidden={!alertModalOpen}>
      <div className="flex flex-col w-full gap-6 h-full">
        {typeof alertModalOpen === "string" ? (
          <></>
        ) : (
          <p className="text-xl font-medium">
            {alertModalOpen.length} Issues Found
          </p>
        )}
        {typeof alertModalOpen === "string" ? (
          <p>{alertModalOpen}</p>
        ) : (
          alertModalOpen.map((error, index) => {
            return (
              <div key={index}>
                <p className="text-left">● {error}</p>
              </div>
            );
          })
        )}

        <div className="flex flex-row items-center justify-end pb-2">
          <div
            className={`w-fit rounded-md px-4 py-1 border border-dark-blue text-dark-blue cursor-pointer`}
            onClick={() => {
              setAlertModalOpen("");
            }}
          >
            Close
          </div>
        </div>
      </div>
    </Modal>
  );
}
