export const TASK_TYPE_OPTIONS = [
  { value: null, label: "All Task Types" },
  {
    label: "BSA",
    options: [
      {
        value: "bsa/",
        label: "All BSA",
      },
      {
        value: "bsa/splitter",
        label: "BSA Splitter",
      },
      {
        value: "bsa/indexing",
        label: "BSA Indexing",
      },
      {
        value: "bsa/data-alignment",
        label: "BSA Data Alignment",
      },
      {
        value: "bsa/exclusions",
        label: "BSA Exclusions",
      },
    ],
  },
  {
    label: "SubPrep",
    options: [
      {
        value: "subprep/",
        label: "All SubPrep",
      },
      {
        value: "splicer",
        label: "Splicer",
      },
      {
        value: "splitter",
        label: "Splitter",
      },
      {
        value: "indexing",
        label: "Indexing",
      },
      {
        value: "data-alignment",
        label: "Data Alignment",
      },
      {
        value: "entity-resolution",
        label: "Entity Resolution",
      },
      {
        value: "conditions",
        label: "Conditions",
      },
    ],
  },
];
