import React from "react";

const Modal = React.forwardRef(
  (
    {
      clear = false,
      children,
      hidden,
      overflowY = "auto",
      sideComponent = <></>,
      maxHeight = "36rem",
      width = "36rem",
      px = "",
      py = "",
    },
    ref
  ) => {
    return (
      <div
        className={`${hidden ? "hidden" : "block"} fixed inset-0 ${
          clear ? "" : "bg-gray-400"
        } bg-opacity-50 transition-opacity flex flex-col items-center justify-center`}
        style={{ zIndex: 2000 }}
      >
        <div className="flex flex-row">
          <div
            ref={ref}
            className={`flex flex-col w-3/4 min-h-0 ${px ? px : "px-8"} ${
              py ? py : "py-6"
            } bg-white rounded-lg justify-center items-center text-center`}
            style={{
              overflowY: overflowY,
              maxHeight: maxHeight,
              width: width,
            }}
          >
            {children}
          </div>
          {sideComponent}
        </div>
      </div>
    );
  }
);

Modal.displayName = "Modal";
export default Modal;
